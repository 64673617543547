<template>
  <div class="expert_tm_section" id="home">
    <div class="expert_tm_hero">
      <div class="container">
        <div class="hero_inner">
          <div class="content">
            <span class="hello">Hello, I´m</span>
            <h3 class="name">Martin</h3>
            <div class="job">
              <span class="cd-headline rotate-1">
                <!-- ANIMATE TEXT VALUES: zoom, rotate-1, letters type, letters rotate-2, loading-bar, slide, clip, letters rotate-3, letters scale, push,  -->
                <span class="cd-words-wrapper">
                  <b :class="text === 1 ? `is-visible` : `is-hidden`">
                    Cloud Architect
                  </b>
                  <b :class="text === 2 ? `is-visible` : `is-hidden`">
                    Developer
                  </b>
                  <b :class="text === 3 ? `is-visible` : `is-hidden`">
                    DevOps Engineer
                  </b>
                  <b :class="text === 4 ? `is-visible` : `is-hidden`">
                    TechLead
                  </b>
                    <b :class="text === 5 ? `is-visible` : `is-hidden`">
                    AI Enthusiast
                  </b>
                </span>
              </span>
            </div>
            <div class="hero_buttons">
              <div class="expert_tm_button">
                <a class="anchor" href="#contact"
                  ><span v-html="`Say hello ` + msg"> </span
                ></a>
              </div>
              <div class="simple_button">
                <a class="anchor" href="#about">About me</a>
              </div>
            </div>
          </div>
          <div class="avatar parallax" data-relative-input="true">
            <div class="main">
              <img src="img/hero/martin_kalsow.png" alt="" class="layer" data-depth="0.1" />
            </div>
            <span class="one"
              ><span class="in layer" data-depth="0.14"></span
            ></span>
            <span class="two"
              ><span class="in layer" data-depth="0.18"></span
            ></span>
            <span class="three"
              ><span class="in layer" data-depth="0.22"></span
            ></span>
            <span class="four"
              ><span class="in layer" data-depth="0.26"></span
            ></span>
            <span class="five"
              ><span class="in layer" data-depth="0.3"></span
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parallax } from "../utils/utils";
import { fb, insta, msg, linkein } from "./svgImage";

export default {
  name: "Home",
  data() {
    return {
      msg,
      fb,
      insta,
      linkein,
      text: 1,
    };
  },
  mounted() {
    parallax();
    const interval = setInterval(() => {
      this.text = this.text < 5 ? this.text + 1 : 1;
    }, 5000);
    return () => clearInterval(interval);
  },
};
</script>
