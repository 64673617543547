<template>
  <div class="expert_tm_all_wrap" data-magic-cursor="show">
    <MobileMenu />
    <TopBar />
    <Home />
    <About />
    <Skills />
    <Service />
    <Courage />
    <Contact />
    <BottomBar />
  </div>
</template>

<script>
import About from "../components/About.vue";
import Contact from "../components/Contact.vue";
import Courage from "../components/ArtificialIntelligence.vue";
import Home from "../components/Home.vue";
import MobileMenu from "../components/layouts/MobileMenu.vue";
import Service from "../components/Service.vue";
import Skills from "../components/Skills.vue";
import BottomBar from "@/components/layouts/BottomBar.vue";
import TopBar from "@/components/layouts/TopBar.vue";
export default {
  name: "Index",
  components: {
    TopBar,
    Home,
    About,
    Skills,
    Service,
    Courage,
    Contact,
    BottomBar,
    MobileMenu,
  },
};
</script>
