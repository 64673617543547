<template>
  <div class="expert_tm_section" id="about">
    <div class="expert_tm_about">
      <div class="about_inner">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/45-49.jpg" alt="" />
            <div class="main" data-img-url="img/about/kalsow-expert-stairs.png"></div>
            <span
              class="square moving_effect"
              data-direction="y"
              data-reverse="yes"
            ></span>
            <span class="border"
              ><span
                class="in moving_effect"
                data-direction="x"
                data-reverse="yes"
              ></span
            ></span>
          </div>
        </div>
        <div class="center">
          <div class="title wow fadeInUp" data-wow-duration="1s">
            <span class="small">About me</span>
            <h3>I´m <span>Martin</span></h3>
            <h3>from <span>Hamburg</span></h3>

          </div>
          <div class="title wow fadeInUp" data-wow-duration="1s">
          <h4><span>I believe that change is uncomfortable but necessary</span></h4>
          The discomfort we experience during periods of change is a natural reaction to the uncertainty associated with entering uncharted territory.
          </div>
          <div
            class="text wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
          </div>
        </div>
        <div class="right">
          <div class="image">
            <img src="img/thumbs/41-61.jpg" alt="" />
            <div class="main" data-img-url="img/about/kalsow-expert-playground.png"></div>
            <div
              class="square moving_effect"
              data-direction="x"
              data-reverse="no"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from "./svgImage";
export default {
  name: "About",
  data() {
    return {
      download: `Download CV ${download}`,
    };
  },
};
</script>
