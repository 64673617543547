<template>
  <div class="expert_tm_section">
    <div class="expert_tm_copyright">
      <div class="container">
        <div class="copyright_inner">
          <div class="top">
            <div class="logo">
              <img src="img/logo/kalsow-expert-logo_dark.png" alt="" />
            </div>
          </div>
          <div class="bottom">
            <div class="left">
            </div>
            <div class="right">
              <ul>
                <li><a class="line_anim" href="impressum.html">Impressum</a></li>
              </ul>
            </div>
          </div>
          <span
            class="border moving_effect"
            data-direction="y"
            data-reverse="yes"
          ></span>
        </div>
      </div>
      <span class="square moving_effect" data-direction="x"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomBar",
};
</script>
