var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expert_tm_section",attrs:{"id":"contact"}},[_c('div',{staticClass:"expert_tm_contact"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"contact_inner"},[_c('div',{staticClass:"left wow fadeInLeft",attrs:{"data-wow-duration":"1s"}},[_c('ul',[_c('li',[_c('div',{staticClass:"list_inner",domProps:{"innerHTML":_vm._s(
                  _vm.msg +
                  `<span>Email</span>
                <h3>
                  <a class='line_anim' href='mailto:mkalsow@gmx.de'
                    >mkalsow@gmx.de</a
                  >
                </h3>`
                )}})])])]),_c('div',{staticClass:"left wow fadeInRight",attrs:{"data-wow-duration":"1s"}},[_c('ul',[_c('li',[_c('div',{staticClass:"list_inner",domProps:{"innerHTML":_vm._s(
                    _vm.twitter +
                    `<span>LinkedIn</span>
                   <h3>
                       <a class='line_anim' href='https://de.linkedin.com/in/martin-kalsow-37b16128b' target='_blank'
                    >follow me</a
                  >
                </h3>`
                  )}})])])])])]),_c('span',{staticClass:"square moving_effect",attrs:{"data-direction":"y","data-reverse":"yes"}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expert_tm_main_title",attrs:{"data-text-align":"center","data-color":"dark"}},[_c('span',[_vm._v("Get in Touch")]),_c('h3',[_vm._v("Connect with Me")])])
}]

export { render, staticRenderFns }