<template>
  <div class="expert_tm_mobile_menu">
    <div class="mobile_menu_inner">
      <div class="mobile_in">
        <div class="logo">
          <a href="#home"><img src="img/logo/kalsow-expert-logo.png" alt="" /></a>
        </div>
        <div class="trigger">
          <div
            class="hamburger hamburger--slider"
            @click="toggle = !toggle"
            :class="toggle ? 'is-active' : ''"
          >
            <div class="hamburger-box">
              <div class="hamburger-inner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dropdown" :style="{ display: toggle ? `block` : `none` }">
      <div class="dropdown_inner">
        <ul class="anchor_nav">
          <li class="current"><a href="#home">home</a></li>
          <li><a href="#about">about me</a></li>
          <li><a href="#service">my talks</a></li>
          <li><a href="#ai">artificial intelligence</a></li>
          <li><a href="#contact">contact</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  data() {
    return {
      toggle: false,
    };
  },
};
</script>
