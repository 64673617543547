<template>
  <div class="expert_tm_section" id="ai">
    <div class="expert_tm_courage">
      <div class="container">
        <div>
          <ul>
            <li class="simple wow fadeInLeft" data-wow-duration="1s">
              <div >
                <div
                    class="expert_tm_main_title"
                    data-text-align="left"
                    data-color="light"
                >
                  <span></span>
                  <p></p>
                  <div class="headline">My first AI experiences</div>
                  <p></p>
                  <form id="aiRequestForm">
                    <div>
                      <label for="aiRequest">
                        <div class="ai_headline">Here is my first own test with a model. It is the anthropic.claude-v2. In my first iteration it is only zero-shot prompting. Next, I will train it with information about myself, and then you can get your questions about me answered here.</div>
                      </label>
                      <textarea type="text" id="aiRequest" name="ai" value="" maxlength="130" maxheight="100" size="30"
                                cols="30" rows="5" style="resize: none;"/>
                    </div>
                    <input class="button" type="submit" value="Submit"/>
                  </form>
                  <div>
                      <div id="response" class="ai_answer">Here comes the answer - be patient.</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="avatar" data-relative-input="true">
            <span class="one"
            ><span class="in layer" data-depth="0.14"></span
            ></span>
            <span class="three"
            ><span class="in layer" data-depth="0.22"></span
            ></span>
            <span class="four"
            ><span class="in layer" data-depth="0.26"></span
            ></span>
            <span class="five"
            ><span class="in layer" data-depth="0.3"></span
            ></span>
          </div>
        </div>
      </div>
      <span
          class="square_left moving_effect"
          data-direction="y"
          data-reverse="yes"
      ></span>
      <span
          class="square_right moving_effect"
          data-direction="y"
          data-reverse="yes"
      ></span>

    </div>
    <div class="expert_tm_courage">
      <div class="container">
        <div class="courage_list">
          <ul>
            <li class="simple wow fadeInLeft" data-wow-duration="1s">
              <img src="img/ai/ai.png" alt="" class="layer" data-depth="0.1" />
              <div class="list_inner">
                <div
                    class="expert_tm_main_title"
                    data-text-align="left"
                    data-color="light"
                >
                </div>
              </div>
            </li>
          </ul>
          <div class="avatar" data-relative-input="true">
          </div>
        </div>
      </div>
      <span
          class="square_left moving_effect"
          data-direction="y"
          data-reverse="yes"
      ></span>
      <span
          class="square_right moving_effect"
          data-direction="y"
          data-reverse="yes"
      ></span>
    </div>


  </div>
</template>

<script>
import {parallax} from "../utils/utils";
import {msg} from "./svgImage";

export default {
  name: "AI",
  data() {
    return {
      msg,
      active: 0,
    };
  },
  props: {
    dark: Boolean,
  },
  mounted() {
    parallax();
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
};

document.addEventListener("DOMContentLoaded", () => {
  const formData = document.querySelector("#aiRequestForm");

  async function sendData() {

    const aiRequest = document.getElementById("aiRequest").value;

    const object = {
      prompt: aiRequest
    };
    const jsonBody = JSON.stringify(object);
    console.log("jsonBody: " + jsonBody);
    try {
      const response = await fetch("https://q5v8w6pzs5.execute-api.us-east-1.amazonaws.com/prod/ai_is_fun", {
        method: "POST",
        body: jsonBody,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Headers': '*'
        }
      });

      const answer = await response.text();
      console.log("response answer: " + answer);

      const removed_new_line = answer.replace(/\\n/g," ");
      document.getElementById("response").innerHTML = removed_new_line;

    } catch (e) {
      console.error(e);
    }
  }

  // Take over form submission
  formData.addEventListener("submit", (event) => {
    event.preventDefault();
    sendData();
  });
});
</script>
