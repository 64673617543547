<template>
  <div class="expert_tm_section" id="contact">
    <div class="expert_tm_contact">
      <div class="container">
        <div
            class="expert_tm_main_title"
            data-text-align="center"
            data-color="dark"
        >
          <span>Get in Touch</span>
          <h3>Connect with Me</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <ul>
              <li>
                <div
                    class="list_inner"
                    v-html="
                    msg +
                    `<span>Email</span>
                  <h3>
                    <a class='line_anim' href='mailto:mkalsow@gmx.de'
                      >mkalsow@gmx.de</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="left wow fadeInRight" data-wow-duration="1s">
              <ul>
                <li>
                  <div
                      class="list_inner"
                      v-html="
                      twitter +
                      `<span>LinkedIn</span>
                     <h3>
                         <a class='line_anim' href='https://de.linkedin.com/in/martin-kalsow-37b16128b' target='_blank'
                      >follow me</a
                    >
                  </h3>`
                    "
                  ></div>
                </li>
              </ul>
            </div>
        </div>
      </div>
      <span
          class="square moving_effect"
          data-direction="y"
          data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import {call, msg, linkein, msgSent} from "./svgImage";

export default {
  name: "Contact",
  data() {
    return {
      msgSent,
      call,
      msg,
      twitter: linkein,
      fromData: {
        name: "",
        email: "",
        phone: "",
        msg: "",
      },
      error: false,
      success: false,
    };
  },
  methods: {
    handleSubmit() {
      const {name, email, phone, msg} = this.fromData;
      if (name && email && phone && msg) {
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 2000);
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },
  },
};
</script>
