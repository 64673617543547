<template>
  <div class="expert_tm_header">
    <div class="container">
      <div class="inner">
        <div class="logo">
          <a class="light" href="#home"><img src="img/logo/kalsow-expert-logo_dark.png" alt="" /></a>
          <a class="dark" href="#home"
            ><img
              :src="dark ? 'img/logo/kalsow-expert-logo.png' : 'img/logo/kalsow-expert-logo.png'"
              alt=""
          /></a>
        </div>
        <div class="menu">
          <ul class="anchor_nav">
            <li class="current"><a href="#home">home</a></li>
            <li><a href="#about">about me</a></li>
            <li><a href="#service">my talks</a></li>
            <li><a href="#ai">artificial intelligence</a></li>
            <li><a href="#contact">contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scroll_, stickyNav } from "../../utils/utils";

export default {
  name: "TopBar",
  props: {
    dark: Boolean,
  },
  mounted() {
    window.addEventListener("scroll", scroll_);
    window.addEventListener("scroll", stickyNav);
  },
};
</script>
