<template>
  <div class="expert_tm_section" id="service">
    <div class="expert_tm_service">
      <div class="container">
        <div class="service_list">
          <ul>
            <li class="simple wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner">
                <div
                  class="expert_tm_main_title"
                  data-text-align="left"
                  data-color="dark"
                >
                  <span>I like to speak</span>
                  <h3>My talks </h3>
                  <p>
                    To share one's knowledge in an appropriate form allows a person to grow.
                  </p>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/camera-dark.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>

                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/camera.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>

                <div class="title">
                  <h3>Code talks 2016</h3>
                </div>
                <div class="text">
                  <p>
                    All beginnings are difficult - here is one of my first talk as a video.
                  </p>
                </div>
                <a
                  class="expert_tm_full_link"
                  href="https://www.youtube.com/watch?v=zoI5pYW1AB0" target="_blank"
                ></a>
              </div>
            </li>
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/web-dark.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-if="!dark">
                  <img class="svg" src="img/svg/web.svg" alt="" />
                  <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Jax</h3>
                </div>
                <div class="text">
                  <p>
                    Jax is one of the largest conferences in Germany. It was a pleasure to be able to participate there multiple times.
                  </p>
                </div>
                <a
                  class="expert_tm_full_link"
                  href="https://jax.de/speaker/martin-kalsow" target="_blank"
                ></a>
              </div>
            </li>
            <li
                class="wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
              <div class="list_inner tilt-effect">
                <span class="icon" v-if="dark">
                  <img class="svg" src="img/svg/web-dark.svg" alt="" />
                  <img class="back" src="img/svg/service-dark.png" alt="" />
                </span>
                <span class="icon" v-else>
                  <img class="svg" src="img/svg/web.svg" alt="" />
                    <img class="back" src="img/svg/service.png" alt="" />
                </span>
                <div class="title">
                  <h3>Api Conference</h3>
                </div>
                <div class="text">
                  <p>
                    The API Conference proves how important APIs will become in the future.
                  </p>
                </div>
                <a
                    class="expert_tm_full_link"
                    href="https://apiconference.net/speaker/martin-kalsow/" target="_blank"
                ></a>
                <img
                    class="popup_service_image"
                    src="img/service/2.jpg"
                    alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <span
        class="square_left moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
      <span
        class="square_right moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import {msg} from "./svgImage";
export default {
  name: "Service",
  data() {
    return {
      msg,
      active: 0,
    };
  },
  props: {
    dark: Boolean,
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
};
</script>
