const expert_tm_preloader = () => {
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  )
    ? true
    : false;
  let preloader = document.getElementById("preloader");
  if (preloader) {
    if (!isMobile) {
      setTimeout(function () {
        preloader.classList.add("preloaded");
      }, 800);
      setTimeout(function () {
        preloader.remove();
      }, 2000);
    } else {
      preloader.remove();
    }
  }
};

export const wowJsAnimation = () => {
  let WOW = require("wowjs");
  new WOW.WOW().init();
};

export const preloader = () => {
  expert_tm_preloader();
  setTimeout(() => {
    document.querySelector("body").classList.add("opened");
  }, 1500);
};

// Header
export const stickyNav = () => {
  let offset = window.scrollY;
  const stickys = document.querySelectorAll(".expert_tm_header");
  stickys.forEach((sticky) => {
    if (sticky) {
      if (offset > 100) {
        sticky.classList.add("animate");
      } else {
        sticky.classList.remove("animate");
      }
    }
  });
};

export const activeSkillProgress = () => {
  const progress_inner = document.querySelectorAll(".skillsInner___"),
    triggerBottom = (window.innerHeight / 5) * 5;
  progress_inner.forEach((box) => {
    const boxTop = box.getBoundingClientRect().top,
      boxElement = box.getElementsByClassName("bar"),
      boxItem = boxElement[0],
      pWidth = box.getAttribute("data-value");
    if (boxTop < triggerBottom) {
      boxItem.classList.add("open");
      boxItem.getElementsByClassName("bar_in")[0].style.width = `${pWidth}%`;
    } else {
      boxItem.classList.remove("open");
    }
  });
};
export const aTagClick = () => {
  const aTag = document.querySelectorAll("[href='#']");
  for (let i = 0; i < aTag.length; i++) {
    const a = aTag[i];
    a.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }
};
// Data image
export const dataImage = () => {
  let d = document.querySelectorAll("[data-img-url");
  for (let i = 0; i < d.length; i++) {
    const element = d[i];
    element.style.backgroundImage = `url(${element.getAttribute(
      "data-img-url"
    )})`;
  }
};
// Moveing effect
export const expert_tm_moving_animation = () => {
  var detail = document.querySelectorAll(".moving_effect");
  var offset = 0;
  detail.forEach((element) => {
    var direction = element.getAttribute("data-direction");
    window.addEventListener("scroll", function () {
      offset = window.scrollY;
      var h = window.innerHeight;
      var i = element.getBoundingClientRect().top + window.scrollY - offset - h;
      if (element.getAttribute("data-reverse") == "yes") {
        i *= -1;
      }
      var x = direction === "x" ? (i * 70) / h : 0;
      var y = direction === "x" ? 0 : (i * 70) / h;
      if (element.getAttribute("data-reverse") == "yes") {
        i *= -1;
      }
      if (i * -1 < h + 300 && i < 300) {
        element.style.transform = `translate3d(${x}px,${y}px, 0px)`;
      }
    });
  });
};
// parallax
export const parallax = () => {
  let parallax = document.querySelector(".parallax");
  let Parallax = require("parallax-js");
  new Parallax(parallax, {
    relativeInput: true,
    selector: ".layer",
  });
};

// jarallax
export const jarallaxContent = () => {
  let { jarallax, jarallaxVideo } = require("jarallax");
  jarallaxVideo();
  jarallax(document.querySelectorAll(".jarallax"), {
    speed: 0.5,
    keepImg: true,
    automaticResize: true,
    videoVolume: 0,
  });
};

export const scroll_ = () => {
  const sections = document.querySelectorAll(".expert_tm_section");
  const navLi = document.querySelectorAll(".anchor_nav li");
  let current = "";
  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.clientHeight;
    if (pageYOffset >= sectionTop - sectionHeight / 3) {
      current = section.getAttribute("id");
    }
  });

  navLi.forEach((li) => {
    li.classList.remove("current");
    if (li.getElementsByTagName("a")[0].getAttribute("href") == `#${current}`) {
      li.classList.add("current");
    }
  });
};
