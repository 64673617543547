<template>
  <fragment>
    <PreLoader />
    <router-view />
    <Cursor />
  </fragment>
</template>

<script>
import PreLoader from "@/components/layouts/PreLoader.vue";
import {
  expert_tm_moving_animation,
  aTagClick,
  dataImage,
  preloader,
  wowJsAnimation,
} from "./utils/utils.js";
export default {
  name: "App",
  components: {
    PreLoader,
  },
  mounted() {
    preloader();
    dataImage();
    expert_tm_moving_animation();
    wowJsAnimation();
    aTagClick();
  },
};
</script>
